import React, { useState } from "react";
import { Affix, Tooltip } from "antd";
import "./index.less";
export default props => {
    const [active, setActive] = useState([0, 0, 0, 0]);
    const onMouseChange = target => {
        setActive(v => {
            const [index, val] = target.split("-");
            v[index] = Number(val);
            return [...v];
        });
    };

    return (
        <Affix styleName='affix' offsetTop={100}>
            <div styleName='content'>
                <div
                    onMouseLeave={() => onMouseChange("0-0")}
                    onMouseEnter={() => onMouseChange("0-1")}
                >
                    <Tooltip
                        styleName='item'
                        placement='left'
                        title={
                            <div>
                                <p style={{ marginBottom: "8px" }}>
                                    使用微信扫一扫
                                </p>
                                <img
                                    style={{ margin: "0 12px 8px" }}
                                    width={200}
                                    height={200}
                                    src={require("@assets/mh/gzh.jpg")}
                                />
                                <p style={{ marginBottom: "8px" }}>
                                    或在微信中搜索 "中国—东盟信息港股份有限公司"
                                </p>
                            </div>
                        }
                    >
                        <img
                            src={
                                !active[0]
                                    ? require("@assets/mh/home/l-3-1.png")
                                    : require("@assets/mh/home/l-3-2.png")
                            }
                        />
                        <span>微信顾问</span>
                    </Tooltip>
                </div>
                <div
                    onMouseLeave={() => onMouseChange("1-0")}
                    onMouseEnter={() => onMouseChange("1-1")}
                >
                    <Tooltip
                        styleName='item'
                        placement='left'
                        title={
                            <div>
                                <p style={{ marginBottom: "8px" }}>
                                    南宁总部地址：中国广西南宁市良庆区秋月路18号，530200
                                </p>
                                <p style={{ marginBottom: "8px" }}>
                                    北京分公司地址：北京市海淀区鲁迅文化园文化创作展示中心2号楼，100097
                                </p>
                                <p style={{ marginBottom: "8px" }}>
                                    联系邮箱：kf1001@caih.com
                                </p>
                                <p style={{ marginBottom: "8px" }}>
                                    联系电话：400-8855-638
                                </p>
                            </div>
                        }
                    >
                        <img
                            src={
                                !active[1]
                                    ? require("@assets/mh/home/l-4-1.png")
                                    : require("@assets/mh/home/l-4-2.png")
                            }
                        />
                        <span>联系我们</span>
                    </Tooltip>
                </div>
                {/* <div
                    onMouseLeave={() => onMouseChange("2-0")}
                    onMouseEnter={() => onMouseChange("2-1")}
                >
                    <Tooltip styleName='item' placement='left'>
                        <img
                            src={
                                !active[2]
                                    ? require("@assets/mh/home/l-2-1.png")
                                    : require("@assets/mh/home/l-2-2.png")
                            }
                        />
                        <span>微信顾问</span>
                    </Tooltip>
                </div> */}
                <div
                    onMouseLeave={() => onMouseChange("3-0")}
                    onMouseEnter={() => onMouseChange("3-1")}
                    onClick={() => window.scrollTo(0, 0)}
                >
                    <div styleName='item' style={{ height: "45px" }}>
                        <img
                            src={
                                !active[3]
                                    ? require("@assets/mh/home/l-1-1.png")
                                    : require("@assets/mh/home/l-1-2.png")
                            }
                        />
                    </div>
                </div>
            </div>
        </Affix>
    );
};
