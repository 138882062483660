import React, { useState } from "react";
import Layout from "@root/src/coms/portalLayout";
import Carousel from "@root/src/coms/portalCarousel";
import CAffix from "@root/src/coms/portalAffix";
import router from "../../data/router";
import { message } from "antd";
import "./index.less";
const data = [
    {
        name: "易融云",
        children: [
            {
                name: "保理云平台",
                icon: require("@assets/mh/home/bl.png"),
                desc: "基于产业大数据的融资平台",
                tags: ["数据驱动", "弱确权", "正向保理", "易融资"],
                link: "/product/bly"
            },
            {
                name: "e信平台",
                icon: require("@assets/mh/home/ept.png"),
                desc: "区块链供应链多级流转平台",
                link: "/product/exin",
                tags: ["信用驱动", "强确权", "可拆分", "可流转"]
            },
            {
                name: "薪资保理",
                icon: require("@assets/mh/home/xzbl.png"),
                desc: "围绕应付工资开展保理业务",
                link: "/product/xzbl",
                tags: ["围绕应付工资", "强确权", "模式创新", "模式创新"]
            }
        ]
    },
    {
        name: "资管云",
        children: [
            {
                name: "薪智捷",
                icon: require("@assets/mh/home/zxj.png"),
                desc: "智能便捷的线上酬薪发放服务",
                link: "/product/xzj",
                tags: ["工资发放管理", "安全高效", "方便快捷", "对账精准"]
            },
            {
                name: "金管家",
                icon: require("@assets/mh/home/jgj.png"),
                desc: "安全省心的线上资金管理服务",
                link: "/product/jgj",
                tags: ["资金管理", "安全合规", "渠道多样", "服务专业"]
            }
        ]
    },
    {
        name: "征信云",
        children: [
            {
                name: "企业征信查询",
                icon: require("@assets/mh/home/qyzx.png"),
                desc: "查询境内企业征信报告",
                tags: ["征信查询", "数据丰富", "速度快", "维度广"],
                link: {
                    dev: "http://lhzx.dev.linkfin.caih.local/search/domestic",
                    test: "http://lhzx.test.linkfin.caih.local/search/domestic",
                    uat: "http://lhzx.uat.linkfin.caih.local/search/domestic",
                    pro: "https://lhzx.linkfinance.cn/search/domestic"
                }
            },
            {
                name: "查东盟",
                icon: require("@assets/mh/home/cdm.png"),
                desc: "查询东盟国家企业征信报告",
                tags: ["征信查询", "东盟企业", "数据多", "维度广"],
                link: {
                    dev: "http://cdm.dev.linkfin.caih.local/",
                    test: "http://cdm.test.linkfin.caih.local/",
                    uat: "http://cdm.uat.linkfin.caih.local/",
                    pro: "https://cdm.linkfinance.cn/"
                }
            }
        ]
    }
];
const banner = [
    {
        img: require("@assets/mh/banner.png"),
        text: ["东信金科，一站式金融科技服务", "从金融科技基础产品到金融应用场景服务，东信金科提供完善的产品体系，助力您的业务腾飞"]
    }
];
export default props => {
    const linkFun = link => {
        if (!link) {
            return message.info({ icon: <></>, content: "敬请期待" });
        }
        if (link.toString() == "[object Object]") {
            window.open(link[props.cfg.ENC]);
            return;
        }
        location.href = link;
    };
    return (
        <>
            <Layout router={router}>
                <section>
                    <Carousel child={banner} />
                    <div style={{ position: "relative" }}>
                        <div styleName='floor-second'>
                            <div styleName='first-title'>连接资金方与产业方，赋能中小微企业</div>
                            <div styleName='second-title'>
                                以场景为依托，大数据为基础，人工智能为手段，通过打造“易融云”、“资管云”、“征信云”三个产品，搭建资金融通平台，
                                连接资金方与产业方，服务中小微企业，提供金融科技和投融资服务。
                            </div>
                            <img src={require("@assets/mh/home/2-1.png")} />
                        </div>
                        <div styleName='floor-third'>
                            <p styleName='title'>一站式金融解决方案</p>
                            <div styleName='card'>
                                <div styleName='card-item'>
                                    <div
                                        styleName='card-img'
                                        style={{
                                            backgroundImage: `url(${require("@assets/mh/home/3-1.png")})`
                                        }}
                                    >
                                        供应链金融解决方案
                                    </div>
                                    <div styleName='card-content'>
                                        以场景化、数字化、智能化的创新业务模式服务核心企业及其上下游产融协同生态圈，实现产业链资金通降本增效，提升全产业链金融竞争力。
                                        <a styleName='btn' href='/solution/yry'>
                                            了解详情
                                        </a>
                                    </div>
                                </div>
                                <div styleName='card-item'>
                                    <div
                                        styleName='card-img'
                                        style={{
                                            backgroundImage: `url(${require("@assets/mh/home/3-2.png")})`
                                        }}
                                    >
                                        整体支付结算解决方案
                                    </div>
                                    <div styleName='card-content'>
                                        一站式资金管理云平台，实现全账户管理、全银行覆盖、提供包括账户管理、资金结算、薪酬发放等服务，助力企业资金管理数字化转型。
                                        <a styleName='btn' onClick={() => linkFun()}>
                                            了解详情
                                        </a>
                                    </div>
                                </div>
                                <div styleName='card-item'>
                                    <div
                                        styleName='card-img'
                                        style={{
                                            backgroundImage: `url(${require("@assets/mh/home/3-3.png")})`
                                        }}
                                    >
                                        征信服务解决方案
                                    </div>
                                    <div styleName='card-content'>
                                        应用人工智能、区块链、云计算和大数据等创新技术构建一站式征信服务平台，链接政府、金融、企业和征信服务机构，助力中小微企业融资和社会信用体系建设。
                                        <a styleName='btn' href='/solution/yak'>
                                            了解详情
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div styleName='floor-fourth'>
                            <p styleName='title'>行业领先的金融科技产品</p>
                            <div styleName='content'>
                                {data.map(v => {
                                    return (
                                        <div styleName='colum' key={v.name}>
                                            <p styleName='colum-title'>{v.name}</p>
                                            {v.children.map((c, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        styleName='colum-item'
                                                        onClick={() => {
                                                            linkFun(c.link);
                                                        }}
                                                    >
                                                        <div styleName='top'>
                                                            <div styleName='left'>
                                                                <img src={c.icon} />
                                                            </div>
                                                            <div styleName='right'>
                                                                <div styleName='right-top'>{c.name}</div>
                                                                <div styleName='right-bottom'>{c.desc}</div>
                                                            </div>
                                                        </div>
                                                        <div styleName='bottom'>
                                                            {c.tags.map(tag => {
                                                                return (
                                                                    <div key={tag} styleName='tag'>
                                                                        {tag}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                                <a styleName='more' href='/product'>
                                    更多产品 &gt;
                                </a>
                            </div>
                        </div>
                        <div styleName='floor-firth'>
                            <p styleName='title'>成功服务的平台案例</p>
                            <div styleName='content'>
                                <div styleName='row'>
                                    <div styleName='card'>
                                        <img src={require("@assets/mh/home/pic_lxy.png")} />
                                        <p styleName='card-title'>路信云</p>
                                        <p styleName='card-content'>
                                            综合运用新一代数字化技术，旨在为建筑企业和工人提供找项目、找资金、找工人、找材料、找设备、培训等功能的建筑产业数字化综合服务平台
                                        </p>
                                    </div>
                                    <div styleName='card'>
                                        <img src={require("@assets/mh/home/pic_rx.png")} />
                                        <p styleName='card-title'>润信</p>
                                        <p styleName='card-content'>
                                            基于云计算、大数据、区块链技术打造的供应链金融服务平台，致力于解决华润集团产业链上下游企业结算方式单一、票据拆分难、转让难、融资难等问题
                                        </p>
                                    </div>
                                    <div styleName='card'>
                                        <img src={require("@assets/mh/home/pic_xyl.png")} />
                                        <p styleName='card-title'>桂建通在线直发</p>
                                        <p styleName='card-content'>
                                            支持广西建筑农民工实名制管理公共服务平台实现在线发放农民工工资，以满足建筑施工领域农民工实名制、分账制、银行代发制的管理要求
                                        </p>
                                    </div>
                                </div>
                                <div styleName='row'>
                                    <div styleName='card'>
                                        <img src={require("@assets/mh/home/pic_yjb.png")} />
                                        <p styleName='card-title'>云建宝在线直发</p>
                                        <p styleName='card-content'>
                                            支持云南省建筑工人实名制管理及工资代发平台实现在线发放农民工工资，以满足建筑施工领域农民工实名制、分账制、银行代发制的管理要求
                                        </p>
                                    </div>
                                    <div styleName='card'>
                                        <img src={require("@assets/mh/home/pic_cdm.png")} />
                                        <p styleName='card-title'>征信云</p>
                                        <p styleName='card-content'>
                                            致力于推进社会信用体系建设，基于大数据、区块链和人工智能技术，依托特色产业数据，赋能中小微企业信用融资
                                        </p>
                                    </div>
                                    <div styleName='card'>
                                        <img src={require("@assets/mh/home/pic_zx.png")} />
                                        <p styleName='card-title'>跨境征信</p>
                                        <p styleName='card-content'>
                                            积极参与面向东盟金融开放门户建设，搭建中国—东盟跨境征信服务平台，助力经贸合作效率提升、探索跨境征信合作
                                        </p>
                                    </div>
                                </div>
                                {/* <a styleName='more' href=''>
                                    更多产品 &gt;
                                </a> */}
                            </div>
                        </div>
                        <div styleName='floor-sixth'>
                            <p styleName='title'>我们的合作伙伴</p>
                            <div styleName='content'>
                                <div styleName='row'>
                                    <img src={require("@assets/mh/home/bank/gs.png")} />
                                    <img src={require("@assets/mh/home/bank/ny.png")} />
                                    <img src={require("@assets/mh/home/bank/zg.png")} />
                                    <img src={require("@assets/mh/home/bank/js.png")} />
                                    <img src={require("@assets/mh/home/bank/yz.png")} />
                                </div>
                                <div styleName='row'>
                                    <img src={require("@assets/mh/home/bank/zx.png")} />
                                    <img src={require("@assets/mh/home/bank/gd.png")} />
                                    <img src={require("@assets/mh/home/bank/hx.png")} />
                                    <img src={require("@assets/mh/home/bank/pf.png")} />
                                    <img src={require("@assets/mh/home/bank/bbw.png")} />
                                </div>
                                <div styleName='row'>
                                    <img src={require("@assets/mh/home/bank/gl.png")} />
                                    <img src={require("@assets/mh/home/bank/gxncxys.png")} />
                                    <img src={require("@assets/mh/home/bank/xy.png")} />
                                    <img src={require("@assets/mh/home/bank/ynncxys.png")} />
                                    <img src={require("@assets/mh/home/bank/fd.png")} />
                                </div>
                            </div>
                        </div>
                        <CAffix />
                    </div>
                </section>
            </Layout>
        </>
    );
};
